import React, { useState } from 'react';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';
import Login from '../../components/Login/Login';
import Logo from '../../components/Logo/Logo';
import Snackbar from '../../components/Snackbar/Snackbar';
import style from './AuthPage.module.css';

const AuthPage = () => {
  const [activeComponent, setActiveComponent] = useState('newPassword');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'login':
        return <Login setActiveComponent={setActiveComponent} />;
      case 'forgotPassword':
        return <ForgotPassword setActiveComponent={setActiveComponent} />;
      default:
        return <Login setActiveComponent={setActiveComponent} />;
    }
  };

  return (
    <div className={style.auth_container}>
      <div className={style.left_panel}>
        <Logo />
      </div>
      <div className={style.right_pane}>
        <div className={style.auth_content}>{renderComponent()}</div>
      </div>
      <Snackbar />
    </div>
  );
};

export default AuthPage;
