import PushLogo from './icons/Notification';
import ReportsLogo from './icons/assignment';
import CustomerLogo from './icons/group';
import HistoryLogo from './icons/history';
import TranslatorsLogo from './icons/person_book';
import CancelLogo from './icons/phonelink_erase';
import SettingsLogo from './icons/settings';

export const iconsConfig = {
  customer: { label: 'Клієнти', icon: CustomerLogo },
  translators: { label: 'Перекладачі', icon: TranslatorsLogo },
  history: { label: 'Історія перекладів', icon: HistoryLogo },
  cancel_translation: { label: 'Відміни перекладу', icon: CancelLogo },
  reports: { label: 'Звіти', icon: ReportsLogo },
  push_message: { label: 'Push-повідомлення', icon: PushLogo },
  settings: { label: 'Налаштування', icon: SettingsLogo },
};
