import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import api from '../../../utils/axiosInstance';
import { validateEmail } from '../../../utils/validators';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import style from './EmailForm.module.css';

const EmailForm = ({ onOtpSent }) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid) {
      setIsLoading(true);
      try {
        await api.post('/Auth/PasswordResetRequest', { email });
        onOtpSent(email);
      } catch (error) {
        let errorMessage = 'Виникла помилка';
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = 'Ми не знайшли ваш обліковий запис';
          } else if (error.response.status === 404) {
            errorMessage = 'Network Error';
          }
        } else if (error.message === 'Network Error') {
          errorMessage = 'Network Error';
        }
        dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={style.forgot_pass}>
      <h2 className={style.forgot_title}>Відновлення паролю</h2>
      <p className={style.text_path}>
        Будь ласка, вкажіть електронну пошту, пов'язану з вашим обліковим
        записом, і ми відправимо вам інструкції зі скидання пароля.
      </p>
      <form onSubmit={handleEmailSubmit} className={style.submit_form}>
        <Input
          id="email"
          labelText="EMAIL"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <Button
          type="submit"
          disabled={!isEmailValid || isLoading}
          valid={isEmailValid}
          text={'Відновити пароль'}
        />
      </form>
    </div>
  );
};

export default EmailForm;
