import React, { useState } from 'react';
import BackButton from '../BackButton/BackButton';
import EmailForm from './EmailForm/EmailForm';
import NewPasswordForm from './NewPassword/NewPassword';
import OtpForm from './OtpForm/OtpForm';
import SuccessModal from './SuccessModal/SuccessModal';

const ForgotPassword = ({ setActiveComponent }) => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    otp: '',
  });
  const [step, setStep] = useState('email');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleUserDataChange = (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [field]: value,
    }));
  };

  const handleOtpSent = (email) => {
    handleUserDataChange('email', email);
    setStep('otp');
  };

  const handleOtpVerified = (otp) => {
    handleUserDataChange('otp', otp);
    setStep('newPassword');
  };

  const handlePasswordChanged = (password) => {
    handleUserDataChange('password', password);
    setStep('otpForPassword');
  };

  const handleFinalOtpVerified = (finalOtp) => {
    handleUserDataChange('otp', finalOtp);
    setStep('done');
    setIsSuccessModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSuccessModalOpen(false);
    setActiveComponent('login');
  };

  const renderStep = () => {
    switch (step) {
      case 'email':
        return <EmailForm onOtpSent={handleOtpSent} />;
      case 'otp':
        return (
          <OtpForm email={userData.email} onOtpVerified={handleOtpVerified} />
        );
      case 'newPassword':
        return (
          <NewPasswordForm
            setActiveComponent={setActiveComponent}
            onPasswordChanged={handlePasswordChanged}
          />
        );
      case 'otpForPassword':
        return (
          <OtpForm
            email={userData.email}
            passwordStep
            password={userData.password}
            onOtpVerified={handleFinalOtpVerified}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="forgot-password">
      {!isSuccessModalOpen && (
        <BackButton onClick={() => setActiveComponent('login')} />
      )}
      {renderStep()}
      <SuccessModal isOpen={isSuccessModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default ForgotPassword;
