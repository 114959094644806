import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import api from '../../../utils/axiosInstance';
import OTPInput from '../../InputOTP/InputOtp';
import style from './OtpForm.module.css';

const OtpForm = ({
  email,
  onOtpVerified,
  passwordStep = false,
  password = '',
}) => {
  const [enteredOtp, setEnteredOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpChange = (value) => {
    setEnteredOtp(value);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (passwordStep) {
        await api.post('/Auth/ResetPassword', {
          email,
          password,
          otp: enteredOtp,
        });
        dispatch(
          showSnackbar({
            message: 'Пароль змінено успішно!',
            type: 'success',
          }),
        );
        onOtpVerified(enteredOtp);
      } else {
        await api.post('/Auth/CheckOtp', { email, otp: enteredOtp });
        dispatch(
          showSnackbar({
            message: 'OTP успішно перевірено!',
            type: 'success',
          }),
        );
        onOtpVerified(enteredOtp);
      }
    } catch (error) {
      let errorMessage = 'Виникла помилка';
      if (error.response && error.response.status === 400) {
        errorMessage = 'Невірний OTP код';
      }
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.post('/Auth/ResendPasswordResetRequest', { email });
      dispatch(
        showSnackbar({
          message: 'Запит на повторне відправлення надіслано!',
          type: 'success',
        }),
      );
      setTimer(60);
    } catch (error) {
      let errorMessage = 'Виникла помилка при повторному відправленні запиту';
      if (error.response && error.response.status === 400) {
        errorMessage = 'Не вдалося надіслати повторний запит';
      }
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.pas_otp}>
      <h2 className={style.OTP_title}>
        {passwordStep ? 'Підтвердіть зміни' : 'Введіть код підтвердження'}
      </h2>
      <p className={style.text_path}>
        {passwordStep
          ? 'Введіть OTP код для підтвердження зміни паролю'
          : 'Для підтвердження облікового запису введіть 5-значний код, який ми надіслали на пошту'}
        {email}.
      </p>
      <form onSubmit={handleOtpSubmit}>
        <OTPInput length={5} onChange={handleOtpChange} />
        <div className={style.resend_container}>
          <p className={style.text_path}>Лист не надійшов?</p>
          <Link
            to="#"
            onClick={handleResend}
            className={`${style.text_link} ${timer > 0 ? style.disabled : ''}`}
            style={{ pointerEvents: timer > 0 ? 'none' : 'auto' }}
          >
            {timer > 0
              ? `Надіслати повторно через ${timer}с`
              : 'Надіслати повторно'}
          </Link>
        </div>
        <button
          type="submit"
          className={`${style.submit_button} ${enteredOtp.length !== 5 ? style.invalid : style.valid}`}
          disabled={enteredOtp.length !== 5 || isLoading}
        >
          Підтвердити
        </button>
      </form>
    </div>
  );
};

export default OtpForm;
