import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axiosInstance from '../../utils/axiosInstance';
import { logout } from '../reducers/authReducer';

const handleErrors = (error) => {
  if (!error.response || error.response.status === 404) {
    return 'Network Error';
  } else if (error.response.status === 400 || error.response.status === 401) {
    return 'Невірний email або пароль';
  } else {
    return 'Виникла помилка';
  }
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/Auth/SignIn', credentials);
      const { jwtToken, refreshToken } = response.data;

      localStorage.setItem('jwtToken', jwtToken);
      Cookies.set('refreshToken', refreshToken, {
        secure: true,
        sameSite: 'Strict',
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(handleErrors(error));
    }
  },
);

export const refreshJwtToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await axiosInstance.post('/Auth/Refresh', {
        refreshToken,
      });
      const { jwtToken, refreshToken: newRefreshToken } = response.data;

      localStorage.setItem('jwtToken', jwtToken);
      Cookies.set('refreshToken', newRefreshToken, {
        secure: true,
        sameSite: 'Strict',
      });

      return { jwtToken, refreshToken: newRefreshToken };
    } catch (error) {
      return rejectWithValue(handleErrors(error));
    }
  },
);

export const performLogout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    localStorage.removeItem('jwtToken');
    Cookies.remove('refreshToken');
    dispatch(logout());
  },
);
