import PropTypes from 'prop-types';
import React from 'react';
import style from './BackButton.module.css';
import { ReactComponent as ExitArrow } from './icon/arrow.svg';

const BackButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className={style.backButton}>
      <ExitArrow className={style.icon} />
    </button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
