import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/authSlice';
import clientReducer from './reducers/clientReducer';
import snackbarReducer from './reducers/snackbarSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    snackbar: snackbarReducer,
    client: clientReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
