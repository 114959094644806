import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../../store/reducers/snackbarSlice';
import style from './Snackbar.module.css';

const Snackbar = () => {
  const dispatch = useDispatch();
  const { open, message, type } = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        dispatch(hideSnackbar());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [open, dispatch]);

  const handleClickOutside = useCallback(
    (event) => {
      if (!event.target.closest(`.${style.snackbar}`)) {
        dispatch(hideSnackbar());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [open, handleClickOutside]);

  if (!open) return null;

  const snackbarClass =
    type === 'success' ? style.snackbarSuccess : style.snackbarError;

  return <div className={`${style.snackbar} ${snackbarClass}`}>{message}</div>;
};

export default Snackbar;
