import React from 'react';
import style from './SuccessModal.module.css';
import Confirmation from './icons/Confirmation.png';

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={style.modal_overlay}>
      <div className={style.modal}>
        <div className={style.modal_content}>
          <img
            src={Confirmation}
            alt="confirmation"
            className={style.confir_logo}
          />
          <h2 className={style.modal_title}>Пароль змінено!</h2>
          <p className={style.modal_text}>
            Ваш пароль було успішно змінено,
            <br /> можете перейти до авторизації.
          </p>
          <button onClick={onClose} className={style.modal_button}>
            До авторизації
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
