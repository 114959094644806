import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { refreshJwtToken } from '../store/actions/authActions';
import { logout } from '../store/reducers/authReducer';
import store from '../store/store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let jwtToken = store.getState().app.jwtToken;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        try {
          const response = await store.dispatch(refreshJwtToken());
          jwtToken = response.payload.jwtToken;
        } catch (err) {
          store.dispatch(logout());
          return Promise.reject(err);
        }
      }

      config.headers.Authorization = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
