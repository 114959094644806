import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  jwtToken: localStorage.getItem('jwtToken') || null,
  refreshToken: Cookies.get('refreshToken') || null,
  isAuthenticated: !!localStorage.getItem('jwtToken'),
  loading: false,
  error: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.jwtToken = action.payload.jwtToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.jwtToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.user = null;
      Cookies.remove('refreshToken');
      localStorage.removeItem('jwtToken');
    },
    loadUserSuccess: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  loadUserSuccess,
} = authSlice.actions;

export default authSlice.reducer;
