import React from 'react';
import style from './NavigationButton.module.css';

const NavigationButton = ({ label, icon: Icon, active, onClick }) => (
  <button
    className={active ? style.activeButton : style.navButton}
    onClick={onClick}
  >
    <Icon fill={active ? '#0A8081' : '#596987'} />
    {label}
  </button>
);

export default NavigationButton;
