import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Customer from '../../components/Customer/Customer';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
// import Translators from '../../components/Translators/Translators';
import { performLogout } from '../../store/actions/authActions';
import style from './CustomerManagement.module.css';
import { iconsConfig } from './helpers';
import Logo from './icons/Logo.svg';
import LogoutLogo from './icons/Logout';
import PushLogo from './icons/Notification';

const CustomerManagement = () => {
  const [activeComponent, setActiveComponent] = useState('customer');
  const [isLogoutActive, setIsLogoutActive] = useState(false);
  const dispatch = useDispatch();
  const { jwtToken } = useSelector((state) => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    if (!jwtToken) {
      navigate('/');
    }
  }, [jwtToken, navigate]);

  const handleNavigationClick = (component) => {
    setActiveComponent(component);
  };

  const handleLogout = () => {
    setIsLogoutActive(true);
    dispatch(performLogout());
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'customer':
        return <Customer />;
      // case 'translators':
      //   return <Translators />;
      default:
        return <div>{iconsConfig[activeComponent]?.label}</div>;
    }
  };

  return (
    <div className={style.customers}>
      <div className={style.nav_panel}>
        <div>
          <div className={style.nav_logo}>
            <img src={Logo} alt="WordCraft" />
          </div>
          <nav className={style.top_tabs}>
            {Object.keys(iconsConfig).map(
              (key) =>
                key !== 'push_message' &&
                key !== 'settings' && (
                  <NavigationButton
                    key={key}
                    label={iconsConfig[key].label}
                    icon={iconsConfig[key].icon}
                    active={activeComponent === key}
                    onClick={() => handleNavigationClick(key)}
                  />
                ),
            )}
          </nav>
        </div>
        <div>
          <nav className={style.bottom_tabs}>
            {Object.keys(iconsConfig)
              .filter((key) => key === 'push_message' || key === 'settings')
              .map((key) => (
                <NavigationButton
                  key={key}
                  label={iconsConfig[key].label}
                  icon={iconsConfig[key].icon}
                  active={activeComponent === key}
                  onClick={() => handleNavigationClick(key)}
                />
              ))}
            <button
              className={style.navButton}
              onClick={handleLogout}
              onBlur={() => setIsLogoutActive(false)}
            >
              <LogoutLogo fill={isLogoutActive ? '#0A8081' : '#596987'} />
              Вийти
            </button>
          </nav>
        </div>
      </div>
      <div className={style.management_content}>
        <div className={style.management_header}>
          <h3 className={style.header_title}>
            {iconsConfig[activeComponent].label}
          </h3>
          <button className={style.header_button}>
            <PushLogo fill="#596987" />
          </button>
        </div>
        <div className={style.management_active_component}>
          <div className={style.content_area}>{renderActiveComponent()}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerManagement;
