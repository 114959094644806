import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { login, performLogout, refreshJwtToken } from '../actions/authActions';

const initialState = {
  jwtToken: localStorage.getItem('jwtToken') || null,
  refreshToken: Cookies.get('refreshToken') || null,
  isAuthenticated: !!localStorage.getItem('jwtToken'),
  loading: false,
  error: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.jwtToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.user = null;
    },
    loadUserSuccess(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.jwtToken = action.payload.jwtToken;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshJwtToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshJwtToken.fulfilled, (state, action) => {
        state.loading = false;
        state.jwtToken = action.payload.jwtToken;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = true;
      })
      .addCase(refreshJwtToken.rejected, (state, action) => {
        state.loading = false;
        state.jwtToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        state.error = action.payload;
        Cookies.remove('refreshToken');
        localStorage.removeItem('jwtToken');
      })
      .addCase(performLogout.fulfilled, (state) => {
        state.jwtToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        state.user = null;
      });
  },
});

export const { logout, loadUserSuccess } = authSlice.actions;
export default authSlice.reducer;
