import React from 'react';
import style from './Logo.module.css';
import LogoImg from './images/Logo.svg';

const Logo = () => {
  return (
    <div className={style.logo_container}>
      <img src={LogoImg} alt="LogoBack" className={style.logo} />
    </div>
  );
};

export default Logo;
