import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../store/actions/authActions';
import { showSnackbar } from '../../store/reducers/snackbarSlice';
import { validateEmail } from '../../utils/validators';
import Button from '../Button/Button';
import Input from '../Input/Input';
import style from './Login.module.css';

const Login = ({ setActiveComponent }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [validationError, setValidationError] = useState(null); // Состояние для ошибки валидации
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jwtToken, error: serverError } = useSelector((state) => state.app);

  useEffect(() => {
    if (jwtToken) {
      navigate('/customers');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    if (serverError) {
      dispatch(showSnackbar({ message: serverError, type: 'error' }));
    }
  }, [serverError, dispatch]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
    setValidationError(null);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordValid(value.length > 0);
    // Очистка ошибки валидации при изменении пароля
    setValidationError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEmailValid || !isPasswordValid) {
      setValidationError('Невірний email або пароль');
    } else {
      dispatch(login({ email, password }));
    }
  };

  return (
    <div className={style.login_container}>
      <h2 className={style.login_title}>Ласкаво просимо</h2>
      <form className={style.login_form} onSubmit={handleSubmit}>
        <Input
          id="email"
          labelText="EMAIL"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <Input
          id="password"
          labelText="PASSWORD"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        {/* Отображение ошибки валидации */}
        {validationError && (
          <div className={style.error_message}>{validationError}</div>
        )}
        <div className={style.wrap_link}>
          <Link
            to="#"
            onClick={() => setActiveComponent('forgotPassword')}
            className={style.text_link}
          >
            Забули пароль?
          </Link>
        </div>
        <Button
          type="submit"
          text={'Увійти'}
          disabled={!isEmailValid || !isPasswordValid}
        />
      </form>
    </div>
  );
};

export default Login;
