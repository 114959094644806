import React from 'react';
import styles from './button.module.css';

const Button = ({ text, onClick, type, disabled = false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${styles.submit_button} ${disabled ? styles.invalid : styles.valid}`}
    >
      {text}
    </button>
  );
};

export default Button;
