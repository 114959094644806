import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/styles/App.css';
import ProtectedRoute from './components/PrivateRoute';
import AuthPage from './pages/AuthPage/AuthPage';
import CustomerManagement from './pages/CustomersPage/CustomerManagement';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route
        path="/customers"
        element={
          <ProtectedRoute>
            <CustomerManagement />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
